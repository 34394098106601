import { default as _91_46_46_46slug_93IdFlHi8R6OMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/[...slug].vue?macro=true";
import { default as adressenUTwlB2Dh9jMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexQ6Fcwv85MjMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkers4PTIuXDGO9Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexzxTumClbtrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as indexV5psNuP92aMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexi7u8BFggTLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93QdGMDHoibJMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexAIOiOlDADLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/facturen/index.vue?macro=true";
import { default as indexwPEFK80Xf3Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/index.vue?macro=true";
import { default as indexOq0N0z47OIMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassoDZ5uzMg4FeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as indexQRR2LGxWeRMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as indexl0b5FQtkBQMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeurenzRJMSW7yaeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accountZUj3qBW2l0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account.vue?macro=true";
import { default as loginDvNg2QQGARMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/login.vue?macro=true";
import { default as _91token_93CR3o3YvMngMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93gJasl3nHAxMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexnrXG1mdRdYMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_9350UBQXEIMSMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurdersacademie7tCBjEvPdrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/bestuurdersacademie.vue?macro=true";
import { default as dossiers_45documentenGjGVEnZTOoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as index2fIDb1RKwzMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/index.vue?macro=true";
import { default as bestuurders4oraxcok1qMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders.vue?macro=true";
import { default as dashboardo6wGv92DbuMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/dashboard.vue?macro=true";
import { default as evenementendzo70grfcqMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/evenementen.vue?macro=true";
import { default as index6G10BhaYvmMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/index.vue?macro=true";
import { default as kennisbank5X81ngH874Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/kennisbank.vue?macro=true";
import { default as _91id_93FrWHcV01RXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/klanten/[id].vue?macro=true";
import { default as indexCSmxhhVvOIMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/klanten/index.vue?macro=true";
import { default as afnemenosIx1psyYnMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexsoY58Mk5zPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexUP3ViThDgVMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelenDtlYpFFEv8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen.vue?macro=true";
import { default as indexIGBZ2u1KkPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvenoZw3feNOLXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorizeMhGrCblUcaMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/oauth/authorize.vue?macro=true";
import { default as stylesEdr51xyD4VMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/styles.vue?macro=true";
import { default as aangemelde0nOxVSgA2Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue?macro=true";
import { default as aanmeldenHmBR5WZgwHMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue?macro=true";
import { default as indexZj83w9QREeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/[slug]/index.vue?macro=true";
import { default as indexcLbOO6FkCTMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/index.vue?macro=true";
import { default as trainingenrCWHyBQ8aVMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen.vue?macro=true";
import { default as _91id_93Npby4GT1NqMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexFze7hTPLyMMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93qODsLpTnhrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexhmV5gzkM9OMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/[category]/index.vue?macro=true";
import { default as cartH3XrDCj3CiMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/cart.vue?macro=true";
import { default as checkout5Z7qLEdO2OMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/checkout.vue?macro=true";
import { default as confirmation61y05D7gzTMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/confirmation.vue?macro=true";
import { default as indexy0CNVgGiRdMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/index.vue?macro=true";
import { default as webshopmZWuPtxQtLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop.vue?macro=true";
import { default as zoekenKp4TCakT87Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/zoeken.vue?macro=true";
import { default as indexgyUr7bIfdFMeta } from "~/pages/trainingen/[slug]/index.vue?macro=true";
import { default as component_45stubf3cbxuYJXLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubf3cbxuYJXL } from "/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93IdFlHi8R6OMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/[...slug].vue")
  },
  {
    name: accountZUj3qBW2l0Meta?.name,
    path: "/account",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account.vue"),
    children: [
  {
    name: "account-bedrijfsgegevens-company-adressen",
    path: "bedrijfsgegevens/:company()/adressen",
    meta: adressenUTwlB2Dh9jMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue")
  },
  {
    name: "account-bedrijfsgegevens-company",
    path: "bedrijfsgegevens/:company()",
    meta: indexQ6Fcwv85MjMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue")
  },
  {
    name: "account-bedrijfsgegevens-company-medewerkers",
    path: "bedrijfsgegevens/:company()/medewerkers",
    meta: medewerkers4PTIuXDGO9Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue")
  },
  {
    name: "account-bedrijfsgegevens",
    path: "bedrijfsgegevens",
    meta: indexzxTumClbtrMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/bedrijfsgegevens/index.vue")
  },
  {
    name: "account-communicatievoorkeuren",
    path: "communicatievoorkeuren",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/communicatievoorkeuren/index.vue")
  },
  {
    name: "account-evenementen",
    path: "evenementen",
    meta: indexi7u8BFggTLMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/evenementen/index.vue")
  },
  {
    name: "account-facturen-factuur",
    path: "facturen/:factuur()",
    meta: _91factuur_93QdGMDHoibJMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/facturen/[factuur].vue")
  },
  {
    name: "account-facturen",
    path: "facturen",
    meta: indexAIOiOlDADLMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/facturen/index.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexwPEFK80Xf3Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/index.vue")
  },
  {
    name: "account-ledenvoordelen",
    path: "ledenvoordelen",
    meta: indexOq0N0z47OIMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/ledenvoordelen/index.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap-incasso",
    path: "lidmaatschap/:lidmaatschap()/incasso",
    meta: incassoDZ5uzMg4FeMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap",
    path: "lidmaatschap/:lidmaatschap()",
    meta: indexQRR2LGxWeRMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue")
  },
  {
    name: "account-lidmaatschap",
    path: "lidmaatschap",
    meta: indexl0b5FQtkBQMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/lidmaatschap/index.vue")
  },
  {
    name: "account-nieuwsbriefvoorkeuren",
    path: "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeurenzRJMSW7yaeMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/account/nieuwsbriefvoorkeuren.vue")
  }
]
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginDvNg2QQGARMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/login.vue")
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93CR3o3YvMngMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten-token",
    path: "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93gJasl3nHAxMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten",
    path: "/auth/wachtwoord-vergeten",
    meta: indexnrXG1mdRdYMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue")
  },
  {
    name: bestuurders4oraxcok1qMeta?.name,
    path: "/bestuurders",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders.vue"),
    children: [
  {
    name: "bestuurders-slug",
    path: ":slug()",
    meta: _91slug_9350UBQXEIMSMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/[slug].vue")
  },
  {
    name: "bestuurders-bestuurdersacademie",
    path: "bestuurdersacademie",
    meta: bestuurdersacademie7tCBjEvPdrMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/bestuurdersacademie.vue")
  },
  {
    name: "bestuurders-dossiers-documenten",
    path: "dossiers-documenten",
    meta: dossiers_45documentenGjGVEnZTOoMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/dossiers-documenten.vue")
  },
  {
    name: "bestuurders",
    path: "",
    meta: index2fIDb1RKwzMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/bestuurders/index.vue")
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/dashboard.vue")
  },
  {
    name: "evenementen",
    path: "/evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/evenementen.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index6G10BhaYvmMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/index.vue")
  },
  {
    name: "kennisbank",
    path: "/kennisbank",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/kennisbank.vue")
  },
  {
    name: "klanten-id",
    path: "/klanten/:id()",
    meta: _91id_93FrWHcV01RXMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/klanten/[id].vue")
  },
  {
    name: "klanten",
    path: "/klanten",
    meta: indexCSmxhhVvOIMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/klanten/index.vue")
  },
  {
    name: ledenvoordelenDtlYpFFEv8Meta?.name,
    path: "/ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen.vue"),
    children: [
  {
    name: "ledenvoordelen-slug-afnemen",
    path: ":slug()/afnemen",
    meta: afnemenosIx1psyYnMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue")
  },
  {
    name: "ledenvoordelen-slug",
    path: ":slug()",
    meta: indexsoY58Mk5zPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen/[slug]/index.vue")
  },
  {
    name: "ledenvoordelen",
    path: "",
    meta: indexUP3ViThDgVMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/ledenvoordelen/index.vue")
  }
]
  },
  {
    name: "lid-worden",
    path: "/lid-worden",
    meta: indexIGBZ2u1KkPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/lid-worden/index.vue")
  },
  {
    name: "nieuwsbrief-uitschrijven",
    path: "/nieuwsbrief/uitschrijven",
    meta: uitschrijvenoZw3feNOLXMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/nieuwsbrief/uitschrijven.vue")
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorizeMhGrCblUcaMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/oauth/authorize.vue")
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/styles.vue")
  },
  {
    name: trainingenrCWHyBQ8aVMeta?.name,
    path: "/trainingen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen.vue"),
    children: [
  {
    name: "trainingen-slug-uuid-aangemeld",
    path: ":slug()/:uuid()/aangemeld",
    meta: aangemelde0nOxVSgA2Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue")
  },
  {
    name: "trainingen-slug-uuid-aanmelden",
    path: ":slug()/:uuid()/aanmelden",
    meta: aanmeldenHmBR5WZgwHMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue")
  },
  {
    name: "trainingen-slug",
    path: ":slug()",
    meta: indexZj83w9QREeMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/[slug]/index.vue")
  },
  {
    name: "trainingen",
    path: "",
    meta: indexcLbOO6FkCTMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/trainingen/index.vue")
  }
]
  },
  {
    name: "verkoopkansen-id",
    path: "/verkoopkansen/:id()",
    meta: _91id_93Npby4GT1NqMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/verkoopkansen/[id].vue")
  },
  {
    name: "verkoopkansen",
    path: "/verkoopkansen",
    meta: indexFze7hTPLyMMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/verkoopkansen/index.vue")
  },
  {
    name: webshopmZWuPtxQtLMeta?.name,
    path: "/webshop",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop.vue"),
    children: [
  {
    name: "webshop-category-product",
    path: ":category()/:product()",
    meta: _91product_93qODsLpTnhrMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/[category]/[product].vue")
  },
  {
    name: "webshop-category",
    path: ":category()",
    meta: indexhmV5gzkM9OMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/[category]/index.vue")
  },
  {
    name: "webshop-cart",
    path: "cart",
    meta: cartH3XrDCj3CiMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/cart.vue")
  },
  {
    name: "webshop-checkout",
    path: "checkout",
    meta: checkout5Z7qLEdO2OMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/checkout.vue")
  },
  {
    name: "webshop-confirmation",
    path: "confirmation",
    meta: confirmation61y05D7gzTMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/confirmation.vue")
  },
  {
    name: "webshop",
    path: "",
    meta: indexy0CNVgGiRdMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/webshop/index.vue")
  }
]
  },
  {
    name: "zoeken",
    path: "/zoeken",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/website/pages/zoeken.vue")
  },
  {
    name: "bestuurders-trainingen-slug",
    path: "/bestuurders/trainingen/:slug()",
    meta: indexgyUr7bIfdFMeta || {},
    component: () => import("~/pages/trainingen/[slug]/index.vue")
  },
  {
    name: component_45stubf3cbxuYJXLMeta?.name,
    path: "/evenementen/:pathMatch(.*)",
    component: component_45stubf3cbxuYJXL
  }
]